import type { AnimationEvent } from '@angular/animations';
import { InjectionToken } from '@angular/core';

export const TOAST_ALERT_COMPONENT = new InjectionToken<any>(
  'TOAST_ALERT_COMPONENT',
);

export enum ToastAlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum ToastAlertColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ToastAlertComponentInterface {
  element: HTMLElement;
  state: string;

  // Inputs
  closeable: boolean;
  color: ToastAlertColor;
  content?: string;
  action?: DynamicToastAlertLinkAction;
  id: string;
  timeout?: number;
  type: ToastAlertType;

  handleClose(): void;
  move(position: number): void;
  startTimer(): void;
  resetTimer(): void;
  unsubscribeTimer(): void;
  toggleVisibility(visibility: string): void;
  handleAnimationDone(event: AnimationEvent): void;
}

export type DynamicToastAlertAction = DynamicToastAlertLinkAction;

export interface DynamicToastAlertLinkAction {
  readonly type: 'link';
  readonly text: string;
  readonly url: string;
  readonly trackingLabel: string;
}

export interface DynamicToastAlertOptions {
  message: string;
  id?: string;
  action?: DynamicToastAlertAction;
  type?: ToastAlertType;
  color?: ToastAlertColor;
  indefinite?: boolean;
  timeout?: number;
  closeable?: boolean;
}
